












































import defaultComponent from "../utils/defaultComponent";
import moment from "moment";
import stateManager from "../../../services/stateManager";

export default defaultComponent.apply({
  props: ["value", "label"],
  data() {
    return {
      activePicker: null,
      menu: false,
      dpValue: null,
      tpValue: null,
      timeValue: null,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = this.options.content.dpAttrs.type === "month" ? "MONTH" : "DATE"));
    },
    value(value) {
      this.setValue(value);
    },
  },
  computed: {
    dateTimeStr() {
      const items = [];
      if (this.dpValue) {
        items.push(this.dpValue);
      }
      if (this.tpValue) {
        items.push(this.tpValue);
      }
      return items.join(" ");
    },
  },
  methods: {
    initialize() {
      this.setValue(this.value);
    },
    displayValue(value, format) {
      let m;
      if (!isNaN(value)) {
        const currentTimeZoneOffset = stateManager.get("currentTimeZoneOffset");
        if (!isNaN(parseInt(currentTimeZoneOffset, 10))) {
          value = value + currentTimeZoneOffset;
          m = moment.utc(value);
        }
      }
      if (!m) {
        m = moment(value);
      }
      return m.format(format);
    },
    setValue(value) {
      if (value && value === this.timeValue) {
        return;
      }
      let changed = false;
      if (!value && this.options.content.setDefaultValue) {
        value = new Date().getTime();
        changed = true;
      }
      if (value) {
        this.dpValue = this.displayValue(value, this.options.content.format);
        this.tpValue = this.displayValue(value, this.options.content.timeFormat);
      } else {
        this.dpValue = "";
        this.tpValue = "";
      }
      this.timeValue = value;
      if (changed) {
        this.$emit("input", this.timeValue);
      }
    },
    setDpValue(value) {
      this.dpValue = value;
      this.updateValues();
    },
    setTpValue(value) {
      this.tpValue = value;
      this.updateValues();
    },
    updateValues() {
      let value = moment(this.dateTimeStr).valueOf();
      {
        const currentTimeZoneOffset = stateManager.get("currentTimeZoneOffset");
        if (!isNaN(parseInt(currentTimeZoneOffset, 10))) {
          value -= new Date().getTimezoneOffset() * 60 * 1000;
          value -= currentTimeZoneOffset;
        }
      }
      let m = moment(value);
      if (this.options.content.resultFilter) {
        m = this.options.content.resultFilter(m);
      }
      this.timeValue = m.valueOf();
      this.$emit("input", this.timeValue);
    },
    reset() {
      this.setValue(0);
      this.$emit("input", 0);
    },
  },
  extraOptions: {
    defaultClass: "timePicker",
  },
});
